import { phoneFormat } from '@shared/builders/phone-formart.helper';
import {
  ReferenceContactEnum,
  ReferenceRelationshipEnum
} from '@shared/enums/reference.enum';
import { getSentimentScore, replaceAll } from '@shared/helpers/utils.helper';
import { IAddress } from '@shared/interfaces/address.interface';
import {
  APIDate,
  APIDatetime,
  APIEmail,
  APIResource,
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICandidate } from '@shared/interfaces/candidate.interface';
import { IDecline } from '@shared/interfaces/decline.interface';
import { IEmailActivity } from '@shared/interfaces/email-activity.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IMetadatum } from '@shared/interfaces/metadatum.interface';
import { IPage } from '@shared/interfaces/page.interface';
import { IProfile } from '@shared/interfaces/profile.interface';
import { IQuestionSet } from '@shared/interfaces/question-set.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { IReference } from '@shared/interfaces/reference.interface';
import { IReminder } from '@shared/interfaces/reminder.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { ITextActivity } from '@shared/interfaces/text-activity.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { IEmailValidity } from '@shared/interfaces/validities/email-validity.interface';
import { IPhoneNumberlValidity } from '@shared/interfaces/validities/phone-number-validity.interface';
import { AnswerSet } from '@shared/models/answer-set.model';
import { Candidate } from '@shared/models/candidate.model';
import { Decline } from '@shared/models/decline.model';
import { HiringFirm } from '@shared/models/hiring-firm.model';
import { Metadatum } from '@shared/models/metadatum.model';
import { Page } from '@shared/models/page.model';
import { Profile } from '@shared/models/profile.model';
import { QuestionSet } from '@shared/models/question-set.model';
import { User } from '@shared/models/user.model';
import { PhoneNumber } from 'libphonenumber-js';

export class Reference extends APIResource implements IReference {
  public static readonly TYPES: string[] = [
    'Reference',
    'ProfileReference',
    'RecruiterReference'
  ];

  public id: APIuuid;
  public type: string;
  public email: APIEmail;
  public first_name: string;
  public last_name: string;
  public phone: PhoneNumber;
  public country_code: string;
  public notes: string;
  public company_name: string;
  public internal_id: string;
  public user: IUser;
  public min_manager_references: number;
  public requested_by: string;
  public candidate: ICandidate | IAPIRelationship;
  public profile: IProfile | IAPIRelationship;
  public hiring_firm: IHiringFirm;
  public created_at: APIDatetime;
  public updated_at: APIDatetime;
  public is_pending: boolean;
  public is_active: boolean;
  public is_active_hf = Boolean(true);
  public is_reminders_enabled: boolean;
  public answer_set: AnswerSet;
  public question_set: IQuestionSet;
  public filled_at: APIDatetime;
  public start_date: APIDatetime;
  public end_date: APIDatetime;
  public candidate_position: string;
  public filled: boolean;
  public title: string;
  public specialty: string;
  public relationship: ReferenceRelationshipEnum;
  public contact_type: ReferenceContactEnum;
  public permission_share_clients: boolean;
  public permission_share_candidate: boolean;
  public request_by?: IRecruiter;
  public requested_by_id: string;
  public settings: ISetting;
  public isDisabled?: boolean;
  public has_same_ip: boolean;
  public unsubscribed_from_sms: boolean;
  public metadatum: IMetadatum;
  public language: string;
  public pages: IPage[] = [];
  public score: number;
  public sentiment_score: number;
  public reminder: IReminder;

  public signature: string;
  public signature_url: string;

  public decline: IDecline;

  public reference_email_validity: IEmailValidity;
  public reference_phone_number_validity: IPhoneNumberlValidity;

  public email_activities?: (IEmailActivity | IAPIRelationship)[];
  public text_activities?: (ITextActivity | IAPIRelationship)[];

  public address?: IAddress;
  public skip_phone_number_validation?: boolean;

  constructor(partial: Partial<IReference>) {
    super();

    Object.assign(this, partial);
  }

  public get apiData(): IAPIData {
    const data = {
      data: {
        id: this.id,
        type: 'Reference',
        attributes: {
          internal_id: this.internal_id,
          specialty: this.specialty,
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          phone: this.phone,
          company_name: this.company_name,
          title: this.title,
          relationship: this.relationship,
          country_code: this.country_code,
          is_active_hf: this.is_active_hf,
          is_reminders_enabled: this.is_reminders_enabled,
          permission_share_clients: this.permission_share_clients,
          permission_share_candidate: this.permission_share_candidate,
          language: this.language,
          notes: this.notes,
          contact_type: this.contact_type,
          start_date: this.start_date,
          end_date: this.end_date,
          candidate_position: this.candidate_position,
          skip_phone_number_validation: this.skip_phone_number_validation
        },
        relationships: {
          address: this.address?.apiRelationship,
          request_by: this.request_by?.apiRelationship
        }
      }
    } as IAPIData;

    const attributes = data.data.attributes;
    const relationships = data.data.relationships;
    if (this.answer_set) {
      attributes.title = this.title;
      attributes.relationship = this.relationship;
      attributes.answers = this.answer_set.answers;
    }

    if (this.question_set) {
      relationships.question_set = this.question_set.apiRelationship;
    }

    if (this.candidate) {
      relationships.candidate = (this.candidate as Candidate).apiRelationship;
    }

    if (this.profile) {
      relationships.profile = (this.profile as Profile).apiRelationship;
    }

    return data;
  }

  public get averageSentiment() {
    return getSentimentScore(+this.sentiment_score);
  }

  public get pills_contact_type(): any {
    let label;
    let className;

    switch (this.contact_type) {
      case 'H':
        label = 'Client';
        className = 'v-btn-primary';
        break;

      case 'C':
        label = 'Candidate';
        className = 'v-btn-success';
        break;

      default:
        label = '-';
        className = 'v-btn-secondary';
        break;
    }

    return {
      label,
      class: className
    };
  }

  public get hasSameIPAddress(): boolean {
    return this.has_same_ip;
  }

  public get hasEmailWarning(): boolean {
    return (
      this.reference_email_validity?.is_valid &&
      this.reference_email_validity?.is_invalid
    );
  }

  public get hasPhoneNumberWarning(): boolean {
    return (
      this.reference_phone_number_validity?.is_valid &&
      this.reference_phone_number_validity?.is_invalid
    );
  }

  public get email_validity(): {
    label: string;
    class: string;
    tooltip: string;
  } {
    let className = 'lnr-envelope';
    let tooltip;

    if (!this.email) {
      return {
        label: '-',
        class: null,
        tooltip
      };
    }

    if (
      this.reference_email_validity?.is_valid &&
      this.reference_email_validity?.is_invalid
    ) {
      className += ' v-text-warning';
      tooltip = 'Check email validity';
    } else if (
      this.reference_email_validity?.is_valid &&
      !this.reference_email_validity?.is_invalid
    ) {
      className = 'lnr-envelope-open v-text-success';
      tooltip = 'Delivered';
    } else if (
      !this.reference_email_validity?.is_valid &&
      this.reference_email_validity?.is_invalid
    ) {
      className = 'lnr-envelope v-text-danger';
      tooltip = 'Failed';
    }

    return {
      label: this.email,
      class: className,
      tooltip
    };
  }

  public get phone_number_validity(): any {
    let className = 'lnr-smartphone text-muted';
    let tooltip;

    if (!this.phone_number) {
      return {
        label: '-',
        class: null
      };
    }

    if (this.reference_phone_number_validity?.is_invalid) {
      className = className.replace('text-muted', 'v-text-warning');
      tooltip = 'Check phone number validity';
    }

    if (this.unsubscribed_from_sms) {
      className = className.replace('text-muted', 'v-text-danger');
      tooltip = 'Reference unsubscribed';
    }

    return {
      label: this.phone_number,
      class: className,
      tooltip
    };
  }

  get full_name(): string {
    return this.first_name || this.last_name
      ? `${this.first_name} ${this.last_name}`
      : '';
  }

  public get phone_number(): string {
    return phoneFormat(this.country_code, this.phone);
  }

  get name_label(): string {
    return this.full_name || this.email;
  }

  get lastActivity(): string {
    return this.filled_at || this.created_at;
  }

  get ip_address(): string {
    return this.metadatum && this.metadatum.ip_address;
  }

  public static fromAPI(res: IAPIData): IReference {
    const data = res.data;

    if (!(data && Reference.TYPES.indexOf(data.type) !== -1)) {
      throw new Error('There was a problem parsing Reference API data');
    }

    const relationships = data.relationships;
    const included = res.included;

    const reference = new Reference({
      id: data.id,
      type: data.type,
      ...data.attributes
    });

    const candidate =
      relationships.candidate &&
      included &&
      included.find(
        (i: IAPIDataObject) =>
          i.id === (relationships.candidate as IAPIRelationship).id
      );

    if (candidate) {
      reference.candidate = Candidate.fromAPI({
        data: candidate,
        included
      });
    }

    const metadatum =
      relationships.metadatum &&
      included &&
      included.find(
        (i: IAPIDataObject) =>
          i.id === (relationships.metadatum as IAPIRelationship).id
      );

    if (metadatum) {
      reference.metadatum = Metadatum.fromAPI({
        data: metadatum,
        included
      });
    }

    const qs =
      relationships.question_set &&
      included &&
      included.find(
        (i: IAPIDataObject) =>
          i.id === (relationships.question_set as IAPIRelationship).id
      );

    if (!!qs) {
      reference.question_set = QuestionSet.fromAPI({
        data: qs,
        included
      });
    }

    const hiringFirm =
      included && included.find((i: any) => i.type === 'HiringFirm');
    if (hiringFirm) {
      reference.hiring_firm = HiringFirm.fromAPI({
        data: hiringFirm,
        included
      });
    }

    const profile = included && included.find((i: any) => i.type === 'Profile');
    if (profile) {
      reference.profile = Profile.fromAPI({
        data: profile,
        included
      });
    }

    const pages =
      relationships.pages &&
      ((included && included.filter((i: any) => i.type === 'Page')) ||
        ([] as IAPIRelationship[]));
    if (pages) {
      reference.pages = Page.fromAPIArray({
        data: pages as any,
        included
      });

      if (reference.pages) {
        reference.pages.map((p: IPage) => {
          p.content = replaceAll(p.content, {
            company_name: reference.hiring_firm.name,
            candidate_full_name: (reference.candidate as Candidate).full_name
          });
        });
      }
    }

    if (relationships.answer_set && included) {
      reference.answer_set = reference.filled
        ? AnswerSet.fromAPI({
            data:
              included &&
              included.find(
                (i: IAPIDataObject) =>
                  i.id === (relationships.answer_set as IAPIRelationship).id
              ),
            included
          })
        : undefined;
    }

    if (relationships.user) {
      reference.user = User.fromAPI({
        data:
          included &&
          included.find(
            (i: IAPIDataObject) =>
              i.id === (relationships.user as IAPIRelationship).id
          ),
        included
      });
    }

    if (relationships.decline) {
      reference.decline = Decline.fromAPI({
        data:
          included &&
          included.find(
            (i: IAPIDataObject) =>
              i.id === (relationships.decline as IAPIRelationship).id
          ),
        included
      });
    }

    return reference;
  }

  static fromAPIArray(res: IAPIArrayData): IReference[] {
    const dataArray = res.data;
    const included = res.included;

    return dataArray.map((data: any) => {
      const reference = new Reference({
        id: data.id,
        ...data.attributes
      });

      const relationships = data.relationships;

      if (relationships) {
        const candidate: IAPIRelationship = relationships.candidate;

        if ((res as any).candidate) {
          reference.candidate = (res as any).candidate;
        } else if (included && candidate !== undefined) {
          reference.candidate = Candidate.fromAPI({
            data:
              included &&
              included.find(
                (i: any) =>
                  i.id === (relationships.candidate as IAPIRelationship).id
              ),
            included
          });
        } else if (candidate !== undefined) {
          reference.candidate = relationships.candidate as IAPIRelationship;
        }

        const metadatum: any = relationships.metadatum;
        if (included && metadatum !== undefined) {
          reference.metadatum = Metadatum.fromAPI({
            data:
              included &&
              included.find(
                (i: any) =>
                  i.id === (relationships.metadatum as IAPIRelationship).id
              ),
            included
          });
        }

        if (included && relationships.question_set !== undefined) {
          const qs =
            included &&
            included.find(
              (i: any) =>
                i.id === (relationships.question_set as IAPIRelationship).id
            );

          if (!!qs) {
            reference.question_set = QuestionSet.fromAPI({
              data: qs,

              included
            });
          }
        }

        if (included && relationships.decline) {
          reference.decline = Decline.fromAPI({
            data:
              included &&
              included.find(
                (i: any) =>
                  i.id === (relationships.decline as IAPIRelationship).id
              ),
            included
          });
        }
      }

      return reference;
    });
  }
}
