import { buildHiringFirm } from '@shared/builders/hiring-firm.helper';
import {
  APIuuid,
  IAPIArrayData,
  IAPIData,
  IAPIDataObject,
  IAPIRelationship
} from '@shared/interfaces/api.interface';
import { ICustomer } from '@shared/interfaces/customer.interface';
import { IHiringFirm } from '@shared/interfaces/hiring-firm.interface';
import { IRecruiter } from '@shared/interfaces/recruiter.interface';
import { ISetting } from '@shared/interfaces/setting.interface';
import { ISubscription } from '@shared/interfaces/subscription.interface';
import { IUser } from '@shared/interfaces/user.interface';

export class HiringFirm implements IHiringFirm {
  public static readonly TYPE: string = String('HiringFirm');

  // Relationships
  default_root: IUser;
  roots: IRecruiter[];
  customer: ICustomer;

  // Attributes
  id: APIuuid;
  seq_id: string;
  name: string;
  nickname: string;

  is_standalone: boolean; // identifies if the hiring firm is a standalone firm, meaning no parent or child firms
  // TO DO: remplace logo and logo_url with picture and picture_url
  // (or any other generic name for both candidate and hiring firm)
  logo: string;
  country: string;
  logo_url: string;
  completed_references?: number;
  avg_reference_completion_time?: number;
  avg_candidates_score?: number;
  total_references?: number;
  total_candidates?: number;
  candidate_count_by_date?: any;
  reference_count_by_date?: any;
  top_recruiters?: any[];
  recruiters?: IRecruiter[];
  subscription: ISubscription;
  settings: ISetting;
  is_active: boolean;

  constructor(partial: Partial<IHiringFirm>) {
    Object.assign(this, partial);
  }

  public get apiRelationship(): IAPIRelationship {
    return {
      type: HiringFirm.TYPE,
      id: this.id
    };
  }

  public get apiData(): IAPIData {
    return {
      data: {
        type: HiringFirm.TYPE,
        id: this.id,
        attributes: {
          name: this.name,
          is_active: this.is_active,
          nickname: this.nickname,
          country: this.country,
          settings: this.settings
        }
      }
    } as IAPIData;
  }

  public get reference_completed_percentage(): number {
    if (this.completed_references && this.total_references) {
      return Math.floor(
        (this.completed_references / this.total_references) * 100
      );
    } else {
      return 0;
    }
  }

  public get label(): any {
    if (!!this.nickname) {
      return `${this.name} - ${this.nickname}`;
    }

    return this.name;
  }

  public get pillsType(): any {
    let label = 'Demo';
    let className = 'v-btn-warning';

    if (!this.settings.is_demo_enabled) {
      label = 'Paying';
      className = 'v-btn-success';
    }

    return {
      label,
      class: className
    };
  }

  public static fromAPI(res: IAPIData): IHiringFirm {
    const data = res.data;

    if (!(data && data.type === HiringFirm.TYPE)) {
      throw new Error(
        `There was a problem parsing ${HiringFirm.TYPE} API data`
      );
    }

    const hiringFirm = new HiringFirm({
      id: data.id,
      ...data.attributes
    });

    return buildHiringFirm(hiringFirm, data.relationships, res.included);
  }

  public static fromAPIArray(res: IAPIArrayData): IHiringFirm[] {
    const dataArray = res.data;

    return dataArray.map((data: IAPIDataObject) => {
      const hiringFirm = new HiringFirm({
        id: data.id,
        ...data.attributes
      });

      return buildHiringFirm(hiringFirm, data.relationships, res.included);
    });
  }
}
